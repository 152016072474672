@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Roboto:wght@400;500;700&display=swap");
html{
  /* background-image: url(../img/icon/pattern-01.png); */
  /* background-color: black; */
}

body {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: black;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f8f8f8;
}

@media (max-width: 399px) {
  body {
    font-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

a{
  text-decoration: none;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.slick-initialized .slick-slide:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Old Standard TT", serif;
  font-weight: 400;
  margin: 0px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
  color: #222;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
}

a {
  color: black;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:hover {
  color: #fcd707;
}

input,
select,
textarea,
select {
  border: none;
  width: 100%;
  height: 70px;
  background-color: #f1f1f1;
  padding: 0 40px;
}

textarea {
  height: 250px;
  padding-top: 25px;
}
/* 
::-webkit-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

:-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
} */
/* 
.nice-select {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  border: none;
  height: 70px;
  padding-left: 50px;
  line-height: 70px;
}

.nice-select .list {
  width: 100%;
  border-radius: 0;
}

.nice-select .list li {
  line-height: 35px;
  color: #777;
}

.nice-select .list li:hover, .nice-select .list li.option:hover {
  background-color: #bead8e;
  color: #fff;
}

.nice-select::after {
  display: none;
}

.nice-select::before {
  content: '\f063';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  left: 30px;
  top: 50%;
  color: #bead8e;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
} */


.input-group input,
.input-group select,
.input-group textarea,
.input-group select {
  padding-left: 80px;
}

 .input-group label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #222;
  display: block;
}

.input-group .icon {
  position: absolute;
  left: 40px;
  bottom: 35px;
  font-size: 20px;
  color: #aa7d02;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.input-group.textarea .icon {
  top: 25px;
  font-size: 20px;
  bottom: auto;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.input-group .nice-select {
  padding-left: 80px;
}

.input-group .nice-select::before {
  left: 40px;
}

/*=======  COMMON CLASS  =======*/
@media (min-width: 1600px) {
  .container-custom-one {
    max-width: 1600px;
  }
}

@media (min-width: 1600px) {
  .container-custom-two {
    max-width: 1400px;
  }
}

@media (min-width: 1600px) {
  .container-custom-three {
    max-width: 1420px;
  }
}

.slick-slide img {
  display: inline-block;
}

.bg-black {
  background-color: #222;
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 48px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 4px;
  color:black;
  border: 2px solid  rgb(202, 183, 8);;
}

.main-btn.btn-filled {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.checkbox-confidential{
  width: 20px;
  height: 20px;
}

.main-btn.btn-filled:disabled {
  background-color: lightgray;
  border-color: rgb(155, 152, 152);
  color: rgb(155, 152, 152);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-btn.btn-filled:hover:enabled {
  background-color: transparent;
  color:black;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-btn.btn-border {
  border-color: rgb(202, 183, 8);
}

.main-btn.btn-border:hover {
  background-color:rgb(202, 183, 8);
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.section-title span.title-tag {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .section-title span.title-tag {
    letter-spacing: 2px;
  }
}

@media (max-width: 399px) {
  .section-title span.title-tag {
    font-size: 12px;
  }
}

.section-title h2 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .section-title h2 {
    font-size: 42px;
  }
}

@media (max-width: 399px) {
  .section-title h2 {
    font-size: 32px;
  }
}

.section-title.white-color span.title-tag {
  color: #bead8e;
}

.section-title.white-color h2 {
  color: #fff;
}

.arrow-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.arrow-style .slick-arrow {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  color: #111;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.arrow-style .slick-arrow.next-arrow {
  margin-left: 20px;
}

.arrow-style .slick-arrow:hover, .arrow-style .slick-arrow.next-arrow {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}

/*=======  Preloader  =======*/
.preloader {
  background-color:  #F6BE00;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.preloader .cssload-container {
  display: block;
  width: 97px;
}

.preloader .cssload-loading i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
}

.preloader .cssload-loading i:first-child {
  opacity: 0;
  -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
          animation: cssload-loading-ani2 0.58s linear infinite;
  -webkit-transform: translate(-19px);
          transform: translate(-19px);
}

.preloader .cssload-loading i:nth-child(2),
.preloader .cssload-loading i:nth-child(3) {
  -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
          animation: cssload-loading-ani3 0.58s linear infinite;
}

.preloader .cssload-loading i:last-child {
  -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
          animation: cssload-loading-ani1 0.58s linear infinite;
}

/*=======  ANIMATION  =======*/
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
            transform: translate(39px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
            transform: translate(39px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
  }
}

@keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
  }
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
