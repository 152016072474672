.about-section {
    position: relative;
    z-index: 1;
  }
  
  .about-section .down-arrow-wrap {
    padding-bottom: 125px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .down-arrow-wrap {
      padding-bottom: 90px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .down-arrow-wrap {
      padding-bottom: 90px;
    }
  }
  
  .about-section .down-arrow-wrap a.down-arrow {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    background: #bead8e;
    border-radius: 50%;
    color: #fff;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
            box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    position: relative;
  }
  
  .about-section .down-arrow-wrap a.down-arrow::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1s linear infinite;
            animation: pulse-border-2 1s linear infinite;
  }
  
  .about-section .down-arrow-wrap a.down-arrow::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
            animation: pulse-border-2 1.5s linear infinite;
  }
  
  .about-section .about-title span.title-tag span {
    color: #bead8e;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-title h2 {
      font-size: 45px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-title h2 {
      font-size: 30px;
    }
  }
  
  .about-section .about-features {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[5];
        grid-template-columns: repeat(5, 1fr);
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    grid-column-gap: 35px;
    margin-top: 100px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features {
      grid-column-gap: 20px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features {
      grid-column-gap: 15px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .about-features {
      -ms-grid-columns: (1fr)[3];
          grid-template-columns: repeat(3, 1fr);
      -ms-grid-rows: 2fr;
          grid-template-rows: 2fr;
      grid-column-gap: 15px;
      margin-top: 60px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-features {
      -ms-grid-columns: (1fr)[2];
          grid-template-columns: repeat(2, 1fr);
      -ms-grid-rows: 3fr;
          grid-template-rows: 3fr;
    }
  }
  
  .about-section .about-features li {
    margin-top: 15px;
  }
  
  .about-section .about-features li a {
    background: #fff;
    padding: 55px 0 45px;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
            box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
    text-align: center;
    display: block;
    color: #bead8e;
    position: relative;
    z-index: 1;
  }
  
  .about-section .about-features li a,
  .about-section .about-features li a i,
  .about-section .about-features li a span {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .about-section .about-features li a i {
    font-size: 75px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a i {
      font-size: 55px;
    }
  }
  
  .about-section .about-features li a img {
    max-width: 75px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a img {
      max-width: 55px;
    }
  }
  
  .about-section .about-features li a .title {
    font-size: 12px;
    color: #111;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 3px;
    padding-top: 10px;
    display: block;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features li a .title {
      letter-spacing: 2px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features li a .title {
      letter-spacing: 1px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .about-features li a .title {
      letter-spacing: 1px;
    }
  }
  
  .about-section .about-features li a .hover-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    font-size: 75px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
  
  .about-section .about-features li a:hover {
    background: #bead8e;
    color: #fff;
  }
  
  .about-section .about-features li a:hover .title {
    color: #fff;
  }
  
  .about-section .about-features li a:hover .hover-icon {
    visibility: visible;
    opacity: 0.1;
  }
  
  .about-section .about-features.masonry-layout {
    margin-top: 0;
  }
  
  @media (max-width: 767px) {
    .about-section .about-features.masonry-layout {
      margin-top: 50px;
    }
  }
  
  .about-section .about-features.masonry-layout li {
    margin-top: 0;
  }
  
  .about-section .about-features.masonry-layout li:nth-child(2) {
    margin-top: 100px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(2) {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(2) {
      margin-top: 50px;
    }
  }
  
  .about-section .about-features.masonry-layout li:nth-child(3) {
    margin-top: 200px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
      margin-top: 100px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
      margin-top: 100px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-features.masonry-layout li:nth-child(3) {
      margin-top: 0;
    }
  }
  
  .about-section .about-features.masonry-layout li:nth-child(4) {
    margin-top: 100px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
      margin-top: -50px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-features.masonry-layout li:nth-child(4) {
      margin-top: 50px;
    }
  }
  
  .about-section .about-features-boxes .single-feature-box {
    background-color: #fff;
    padding: 50px 30px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
            box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
    text-align: center;
    font-size: 14px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features-boxes .single-feature-box {
      padding: 50px 18px;
    }
  }
  
  .about-section .about-features-boxes .single-feature-box .icon {
    font-size: 70px;
    margin-bottom: 20px;
    line-height: 1;
    color: #bead8e;
  }
  
  .about-section .about-features-boxes .single-feature-box h4 {
    margin-bottom: 20px;
    font-size: 30px;
    letter-spacing: -1px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-features-boxes .single-feature-box h4 {
      font-size: 24px;
    }
  }
  
  .about-section .about-features-boxes .single-feature-box.only-bg {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: none;
            box-shadow: none;
    min-height: 315px;
    padding: 0;
    background-size: cover;
    background-position: center;
  }
  
  .about-section .about-features-boxes .single-feature-box.dark {
    background-color: #222;
  }
  
  .about-section .about-features-boxes .single-feature-box.dark,
  .about-section .about-features-boxes .single-feature-box.dark h4,
  .about-section .about-features-boxes .single-feature-box.dark h4 a {
    color: #fff;
  }
  
  .about-section .about-features-boxes .single-feature-box.dark a:hover {
    color: #bead8e;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .abour-text {
      padding: 0;
      margin-top: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .abour-text {
      padding: 0;
      margin-top: 50px;
    }
  }
  
  .about-section .about-text-box {
    background-color: #fff;
    padding: 30px;
    margin-top: 40px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-text-box {
      padding: 50px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-text-box {
      padding: 50px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-text-box {
      padding: 40px 20px;
    }
  }
  
  .about-section .about-text-box .about-text {
    font-size: 14px;
    padding-left: 20px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-section .about-text-box .about-text {
      padding: 0;
      margin-top: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .about-section .about-text-box .about-text {
      padding: 0;
      margin-top: 50px;
    }
  }
  
  .about-section .about-text-box .about-text span {
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  
  .about-section .about-text-box .about-text h3 {
    font-size: 48px;
    margin-bottom: 30px;
    letter-spacing: -1px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-section .about-text-box .about-text h3 {
      font-size: 38px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-section .about-text-box .about-text h3 {
      font-size: 34px;
    }
  }
  
  @media (max-width: 575px) {
    .about-section .about-text-box .about-text h3 {
      font-size: 32px;
    }
  }
  
  .about-section .about-text-box .about-text .main-btn {
    margin-top: 30px;
  }
  
  @media (max-width: 575px) {
    .about-section .about-text-box .about-text .main-btn {
      padding: 0 35px;
    }
  }
  
  .about-section .about-right-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  
  .about-section .about-right-bottom .about-bottom-img {
    position: relative;
  }
  
  .about-section .about-right-bottom .about-bottom-img::before {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/icon/pattern-04.png);
    content: '';
    background-size: cover;
    background-position: center;
  }
  