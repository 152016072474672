/* .flota-gird-loop .flota-box {
  height: 550px;
} */
.flota-gird-loop .flota-box .flota-img-wrap {
  /* width: 100%; */
  overflow: hidden;
  height: 240px;
}
  
.flota-gird-loop .flota-box .flota-img-wrap .flota-img {
  height: 100%;
  /* width: 100%; */
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-size: cover;
  background-position: center;
}

.flota-gird-loop .flota-box .flota-img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.flota-gird-loop .flota-box .flota-desc {
  height: 200px;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 50px 35px 30px;
  position: relative;
}

.flota-gird-loop .flota-box .flota-desc .icons .icon-facilitati{
  margin-right: 2px;
}

.flota-gird-loop .flota-box .flota-galerie {
  overflow: hidden;
  height: 120px;
  margin-bottom: 10px;
}
.flota-gird-loop .flota-box .flota-galerie a {
  overflow: hidden;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  max-width: 150px;
}
.flota-gird-loop .flota-box .flota-galerie img {
  border-radius: 5px;
  margin-bottom: 10px;
  width: auto;
  height: 100%;
  opacity: 0.3;
}
.flota-gird-loop .flota-box .flota-galerie img:Hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
  
.flota-gird-loop .flota-box .flota-desc{
background-color:#f8f8f4;
}

.flota-gird-loop .flota-box .flota-desc .flota-title {
  height: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flota-gird-loop .flota-box .flota-desc {
    padding: 50px 25px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flota-gird-loop .flota-box .flota-desc {
    padding: 50px 20px 30px;
  }
}

.flota-gird-loop .flota-box .flota-desc .icons {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 0px;
  position: absolute;
  left: 25px;
  right: 25px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
          box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flota-gird-loop .flota-box .flota-desc .icons {
    left: 12px;
    right: 12px;
    padding: 15px 5px;
  }
}

.flota-gird-loop .flota-box .flota-desc .icons li {
  display: inline-block;
  margin: 0px 10px;
  color: #222;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flota-gird-loop .flota-box .flota-desc .icons li {
    margin: 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flota-gird-loop .flota-box .flota-desc .icons li {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flota-gird-loop .flota-box .flota-desc .icons li {
    margin: 0 5px;
  }
}

@media (max-width: 399px) {
  .flota-gird-loop .flota-box .flota-desc .icons li {
    margin: 0 5px;
  }
}

.flota-gird-loop .flota-box .flota-desc .icons img{
  width: 20px;
  height: 20px;
}
.flota-gird-loop .flota-box .flota-desc .icons img:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  filter: invert(68%) sepia(27%) saturate(4967%) hue-rotate(13deg) brightness(112%) contrast(106%);
  }

.flota-gird-loop .flota-box .flota-desc h4 {
  /* font-size: 24px; */
  letter-spacing: 2px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flota-gird-loop .flota-box .flota-desc h4 {
    font-size: 20px;
  }
}
