

.language-select .dropdown >button{
    background: transparent;
    border:none;
  }
  
  .language-select-dropdown button{
    color: white;
  }
  .language-select-dropdown button:hover{
    color: white;
  }
  .language-select-dropdown-stiky >{
    color: black;
  }
  
  .language-select .dropdown-menu ul,
  .language-select .dropdown-menu li,
  .language-select .dropdown-menu button{
    background: white;
    border:none;
    color: black;
  }