@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar {
      margin-top: 80px;
    }
  }
  
  @media (max-width: 767px) {
    .sidebar {
      margin-top: 80px;
    }
  }
  
  .sidebar .widget {
    padding: 40px;
    background-color: #fff;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget {
      padding: 40px 20px;
    }
  }
  
  @media (max-width: 399px) {
    .sidebar .widget {
      padding: 40px 20px;
    }
  }
  
  .sidebar .widget .widget-title {
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 1;
    position: relative;
    padding-left: 30px;
  }
  
  .sidebar .widget .widget-title::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #bead8e;
    content: '';
  }
  
  .sidebar .widget.about-author-widget .author-box {
    font-size: 14px;
    text-align: center;
  }
  
  .sidebar .widget.about-author-widget .author-box h6 {
    font-size: 24px;
    letter-spacing: -1px;
    margin-top: 30px;
    padding-bottom: 20px;
  }
  
  .sidebar .widget.about-author-widget .author-box .social-icon {
    margin-top: 25px;
  }
  
  .sidebar .widget.about-author-widget .author-box .social-icon li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .sidebar .widget.about-author-widget .author-box .social-icon li a {
    padding: 5px;
    color: #b3bed3;
  }
  
  .sidebar .widget.about-author-widget .author-box .social-icon li a:hover {
    color: #bead8e;
  }
  
  .sidebar .widget.search-widget form {
    position: relative;
  }
  
  .sidebar .widget.search-widget form input {
    width: 100%;
    background-color: #f8f8f8;
    height: 60px;
    border: none;
    padding-left: 30px;
    padding-right: 60px;
    font-size: 14px;
  }
  
  .sidebar .widget.search-widget form button {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    z-index: 1;
    background-color: #bead8e;
    font-size: 14px;
    color: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .sidebar .widget.search-widget form button:hover {
    background-color: #111;
    color: #fff;
  }
  
  .sidebar .widget.popular-feeds .single-popular-feed {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: 80px 1fr;
        grid-template-columns: 80px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e1e6ff;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .sidebar .widget.popular-feeds .single-popular-feed:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .sidebar .widget.popular-feeds .single-popular-feed .feed-desc h6 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
    font-size: 14px;
  }
  
  .sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time i {
    margin-right: 5px;
  }
  
  .sidebar .widget.categories-widget ul li {
    margin-bottom: 10px;
  }
  
  .sidebar .widget.categories-widget ul li:last-child {
    margin-bottom: 0;
  }
  
  .sidebar .widget.categories-widget ul li a {
    font-size: 14px;
    padding-left: 30px;
    height: 50px;
    background-color: #f8f8f8;
    display: block;
    position: relative;
    line-height: 50px;
    color: #777;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .sidebar .widget.categories-widget ul li a span {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #eaeaea;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  
  .sidebar .widget.categories-widget ul li a:hover {
    background-color: #bead8e;
    color: #fff;
  }
  
  .sidebar .widget.categories-widget ul li a:hover span {
    background-color: #947f58;
  }
  
  .sidebar .widget.socail-widget ul li {
    display: inline-block;
    margin-right: 10px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget.socail-widget ul li {
      margin-right: 5px;
    }
  }
  
  @media (max-width: 399px) {
    .sidebar .widget.socail-widget ul li {
      margin-right: 5px;
    }
  }
  
  .sidebar .widget.socail-widget ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #777;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .sidebar .widget.socail-widget ul li a:hover {
    background-color: #bead8e;
  }
  
  .sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter {
    margin-bottom: 30px;
    padding-bottom: 30px;
    font-size: 12px;
    border-bottom: 1px solid #e1e6ff;
    padding-left: 35px;
    position: relative;
  }
  
  .sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '\f099';
    font-family: 'Font Awesome 5 Brands';
    font-size: 14px;
    color: #bead8e;
  }
  
  .sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter a {
    color: #111;
  }
  
  .sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter .date {
    margin-top: 15px;
    font-weight: 500;
  }
  
  .sidebar .widget.instagram-feed-widget ul {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: (1fr)[3];
        grid-template-rows: repeat(3, 1fr);
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  
  .sidebar .widget.popular-tag-widget ul li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  
  .sidebar .widget.popular-tag-widget ul li a {
    font-size: 12px;
    color: #777;
    padding: 10px 20px;
    background-color: #f4f4f4;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .widget.popular-tag-widget ul li a {
      padding: 10px 15px;
    }
  }
  
  @media (max-width: 399px) {
    .sidebar .widget.popular-tag-widget ul li a {
      padding: 10px 15px;
    }
  }
  
  .sidebar .widget.popular-tag-widget ul li a:hover {
    background-color: #bead8e;
  }
  
  .sidebar .widget.banner-ad-widget {
    padding: 0;
    border: none;
  }
  