.anunturi-image{
  display: flex;
  height: 500px;
  /* max-height: 200px; */
  /* object-fit: cover; */
}
.anunturi-image a{
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.anunturi-image img{
  width: 100%;
  height: 100%;
  transition: transform 0.5s linear;
}
.anunturi-image img:hover{
  overflow: hidden;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
  
.anunturi-image-mobile{
  display: flex;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
.anunturi-image-mobile a{
  display: flex;
  overflow: hidden;
  justify-content: center;
justify-content: center;
}
.anunturi-image-mobile img{
  width: 80%;
}

.anunturi-loop {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

@media (max-width: 767px) {
  .anunturi-loop {
    grid-gap: 10px;
  }
}

@media (max-width: 575px) {
  .anunturi-loop {
    grid-gap: 10px;
  }
}

.anunturi-loop .single-anunturi-image {
  overflow: hidden;
}

.anunturi-loop .single-anunturi-image img {
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.anunturi-loop .single-anunturi-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.anunturi-loop .single-anunturi-image:nth-child(3),
.anunturi-loop .single-anunturi-image:nth-child(6),
.anunturi-loop .single-anunturi-image:nth-child(9),
.galanunturilery-loop .single-anunturi-image:nth-child(12) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.anunturi-loop .single-anunturi-image:nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.anunturi-loop .single-anunturi-image:nth-child(6) {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4 / 5;
}

.anunturi-loop .single-anunturi-image:nth-child(9) {
  -ms-grid-row: 6;
  -ms-grid-row-span: 1;
  grid-row: 6 / 7;
}

.anunturi-loop .single-anunturi-image:nth-child(12) {
  -ms-grid-row: 8;
  -ms-grid-row-span: 1;
  grid-row: 8 / 9;
}
