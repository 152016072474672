.home-cauta{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
}
.home-cauta .row{
    justify-content: center;
    align-items: center;
    background: #F6BE00;
    border-radius: 100px;
}

.home-input-group{
    border-radius: 30px;
    background-color: white;
    padding-left: 50px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-input-group input{
    background: transparent;
    height: 50px;
    padding: 0;
    /* color: rgb(240, 131, 131); */
}



.home-cauta-trasee button{
    height: 50px;
    width: 80px;
    border-radius: 25px;
    background: black;
    color:white;
}
.home-cauta-trasee button:hover{
    background:white;
    color: black;
}


@media (max-width: 767px) {
    .home-cauta{
        position: relative;
        margin: 0px;
        padding: 0px;
    }
    .home-cauta .row{
        border-radius: 0px;
        margin: 0px;
        padding: 0px;
    }
}


.home-banner-news{
    position:absolute;
    bottom:50px;
    left:50px;
    background-color: red;
    color: white;
    opacity: 0.4;
    border-radius: 0 50px 0 50px;
    padding: 10px;
    font-weight: 800;
    width: 250px;
    animation: rotation 5s ;
    animation-iteration-count: infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 767px) {
    .home-banner-news{
        bottom:150px;
    }
}

@keyframes rotation {
    0% {
        opacity: 0.8;
        transform: rotate(0deg);
    }
    20% {
        opacity: 0.3;
        transform: rotate(-5deg);
    }
    50% {
        opacity: 0.8;
        transform: rotate(0deg);
    }
    75% {
        opacity: 0.3;
        transform: rotate(5deg);
    }
    100% {
        opacity: 0.8;
        transform: rotate(0deg);
    }

}