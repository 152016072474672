.contact-info .info-box{
    height: 180px;
  }
  
  .contact-part .contact-info .info-box {
    padding: 50px 45px;
    background-color: #fff;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
    -ms-grid-columns: 40px 1fr;
        grid-template-columns: 40px 1fr;
    margin-bottom: 30px;
    grid-column-gap: 30px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-part .contact-info .info-box {
      padding: 50px 30px;
      -ms-grid-columns: 30px 1fr;
          grid-template-columns: 30px 1fr;
    }
  }
  
  @media (max-width: 767px) {
    .contact-part .contact-info .info-box {
      display: block;
      padding: 30px 20px;
    }
  }
  
  .contact-part .contact-info .info-box .icon {
    font-size: 40px;
    color:  #F6BE00;
  }
  
  @media (max-width: 767px) {
    .contact-part .contact-info .info-box .icon {
      margin-bottom: 15px;
    }
  }
  
  .contact-part .contact-info .info-box .desc h4 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-part .contact-maps {
    height: 700px;
    width: 100%;
  }
  
  .contact-part .contact-form {
    background-color: #fff;
    padding: 100px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-part .contact-form {
      padding: 50px;
    }
  }
  
  @media (max-width: 767px) {
    .contact-part .contact-form {
      padding: 50px;
    }
  }
  
  .contact-part .contact-form .main-btn.btn-filled {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  