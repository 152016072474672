.banner-area .banner-content span.promo-tag {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-bottom: 30px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area .banner-content span.promo-tag {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
  
  @media (max-width: 767px) {
    .banner-area .banner-content span.promo-tag {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
  
  .banner-area .banner-content .title {
    font-size: 120px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: -6px;
    padding-bottom: 26px;
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .banner-area .banner-content .title {
      font-size: 100px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area .banner-content .title {
      font-size: 86px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-area .banner-content .title {
      font-size: 72px;
    }
  }
  
  @media (max-width: 575px) {
    .banner-area .banner-content .title {
      font-size: 66px;
    }
  }
  
  @media (max-width: 399px) {
    .banner-area .banner-content .title {
      font-size: 52px;
    }
  }
  
  .banner-area .banner-content ul li {
    display: inline-block;
  }
  
  .banner-area .banner-content ul li:last-child {
    margin-left: 24px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area .banner-content ul li:last-child {
      margin-left: 10px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area .banner-content ul li:last-child {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .banner-area .banner-content ul li:last-child {
      margin-left: 5px;
    }
  }
  
  @media (max-width: 399px) {
    .banner-area .banner-content ul li:last-child {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-area .banner-content .main-btn {
      padding: 0 35px;
      line-height: 55px;
    }
  }
  
  @media (max-width: 575px) {
    .banner-area .banner-content .main-btn {
      padding: 0 25px;
      line-height: 50px;
      letter-spacing: 1px;
    }
  }
  
  .banner-area .banner-thumb .single-thumb {
    overflow: hidden;
  }
  
  .banner-area .banner-thumb .single-thumb img {
    -webkit-animation: zoomIn 15s linear infinite;
            animation: zoomIn 15s linear infinite;
  }
  
  .banner-area.banner-style-one {
    padding-top: 240px;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area.banner-style-one {
      padding-top: 200px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one {
      padding-top: 160px;
      padding-bottom: 120px;
    }
  }
  
  @media (max-width: 767px) {
    .banner-area.banner-style-one {
      padding-top: 160px;
      padding-bottom: 100px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .banner-area.banner-style-one .banner-content {
      padding-bottom: 100px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-area.banner-style-one .banner-content {
      padding-bottom: 100px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content .title {
      font-size: 66px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content ul li:last-child {
      margin-left: 5px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-one .banner-content .main-btn {
      padding: 0 20px;
      line-height: 50px;
      letter-spacing: 1px;
    }
  }
  
  .banner-area.banner-style-two .single-banner, .banner-area.banner-style-three .single-banner {
    height: 950px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  @media (max-width: 767px) {
    .banner-area.banner-style-two .single-banner, .banner-area.banner-style-three .single-banner {
      height: 700px;
    }
  }
  
  .banner-area.banner-style-two .single-banner .banner-bg,
  .banner-area.banner-style-two .single-banner .banner-overly, .banner-area.banner-style-three .single-banner .banner-bg,
  .banner-area.banner-style-three .single-banner .banner-overly {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-size: cover;
    background-position: center;
  }
  
  .banner-area.banner-style-two .single-banner .banner-overly, .banner-area.banner-style-three .single-banner .banner-overly {
    background-color: #222;
    z-index: -1;
    opacity: 0.6;
  }
  
  .banner-area.banner-style-two .single-banner .banner-bg, .banner-area.banner-style-three .single-banner .banner-bg {
    -webkit-animation: zoomIn 15s linear infinite;
            animation: zoomIn 15s linear infinite;
  }
  
  .banner-area.banner-style-two .banner-content,
  .banner-area.banner-style-two .banner-content h1, .banner-area.banner-style-three .banner-content,
  .banner-area.banner-style-three .banner-content h1 {
    color: #fff;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-area.banner-style-two .banner-content h1, .banner-area.banner-style-three .banner-content h1 {
      font-size: 90px;
    }
  }
  
  .banner-area.banner-style-two .banner-content .main-btn.btn-filled, .banner-area.banner-style-two .banner-content .main-btn.btn-border:hover, .banner-area.banner-style-three .banner-content .main-btn.btn-filled, .banner-area.banner-style-three .banner-content .main-btn.btn-border:hover {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .banner-area.banner-style-two .banner-content .main-btn.btn-filled:hover, .banner-area.banner-style-two .banner-content .main-btn.btn-border, .banner-area.banner-style-three .banner-content .main-btn.btn-filled:hover, .banner-area.banner-style-three .banner-content .main-btn.btn-border {
    border-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  
  .banner-area.banner-style-three .single-banner {
    height: auto;
    padding-top: 180px;
    padding-bottom: 125px;
  }
  
  .banner-area .slick-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background: #fff;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 9;
  }
  
  .banner-area .slick-arrow:hover {
    background-color: #bead8e;
    color: #fff;
  }
  
  .banner-area .slick-arrow.next-arrow {
    right: 0;
    left: auto;
  }
  
  .banner-area ul.slick-dots {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-51%);
            transform: translateX(-51%);
    bottom: 30px;
  }
  
  .banner-area ul.slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .banner-area ul.slick-dots li button {
    border: none;
    font-size: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    line-height: 0;
    font-size: 0;
  }
  
  .banner-area ul.slick-dots li.slick-active button {
    background-color: #bead8e;
  }
  