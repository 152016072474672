.counter-section .counter-box {
    margin-bottom: 50px;
  }
  
  @media (max-width: 575px) {
    .counter-section .counter-box {
      font-size: 12px;
    }
  }
  
  .counter-section .counter-box .icon {
    margin-bottom: 30px;
  }
  
  .counter-section .counter-box .icon img {
    max-width: 100px;
  }
  
  .counter-section .counter-box .icon i {
   font-size: 48px;
  }
  
  .counter-section .counter-box,
  .counter-section .counter-box h4 {
    color: #fff;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }
  
  .counter-section .counter-box h4 {
    font-size: 60px;
    word-spacing: 0;
  }
  
  .counter-section .counter-box h4 .plus-icon {
    font-size: 16px;
  }
  
  @media (max-width: 575px) {
    .counter-section .counter-box h4 {
      font-size: 38px;
    }
  }
  
  .counter-section .counter-box .title {
    color: #999999;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.box-layout {
      font-size: 12px;
    }
  }
  
  .counter-section .counter-box.counter-box-two {
    background-color: #fff;
    height: 150px;
    /* padding: 30px 40px 30px 120px; */
    /* position: relative; */
    /* margin-bottom: 30px; */
  }
  /* 
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.counter-box-two {
      padding: 30px 20px 30px 115px;
    }
  }
  
  @media (max-width: 767px) {
    .counter-section .counter-box.counter-box-two {
      padding: 110px 25px 30px;
    }
  } */
  
  .counter-section .counter-box.counter-box-two,
  .counter-section .counter-box.counter-box-two h4 {
    font-weight: 400;
    color: #777;
  }
  
  .counter-section .counter-box.counter-box-two .icon {
    /* position: absolute;
    top: 40px;
    left: 40px; */
    font-size: 60px;
    color: #bead8e;
    margin: 0;
    line-height: 1;
  }
  /* 
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .counter-section .counter-box.counter-box-two .icon {
      left: 30px;
    }
  }
  
  @media (max-width: 767px) {
    .counter-section .counter-box.counter-box-two .icon {
      top: 30px;
      left: 25px;
    }
  } */
  
  .counter-section .counter-box.counter-box-two h4 {
    color: #222;
    letter-spacing: -1px;
    font-family: "Old Standard TT", serif;
  }
  