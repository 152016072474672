.trasee-cautare{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6BE00;
}

.trasee-input-group{
    border-radius: 30px;
    background-color: white;
    padding-left: 50px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.trasee-input-group .icon{
    position:relative;
    top: 0%;
    left: -10px;
}

.trasee-input-group input{
    background: transparent;
    height: 50px;
    padding: 0;
    /* color: rgb(240, 131, 131); */
}

.trasee-cautare>button{
    background: transparent;
}

.row-fluid{
    white-space: nowrap;
}

.row-fluid .col-lg-1,
.row-fluid .col-lg-2,
.row-fluid .col-lg-3 {
    display: inline-block;
    vertical-align: top;
}

.row-fluid .col-lg-2{
    width: 150px;
}

.row-fluid .col-lg-1{
    width: 100px;
}

.traseu-cursa-statii{
    background-color: #F6BE00;
    font-size: 14px;
}
.col-fixed{
    position: sticky!important;
    left:0px!important;
    background-color: white;
}

.trasee-cursa-obs-btn{
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: blue;
    color: #F6BE00;
    font-size: 12px;
    font-weight:bold;
}

[role=tooltip].popup-content{
    width: auto!important;
    max-width: 50vw;
    background-color: #F6BE00;
}
.trasee-cursa-obs-message{
    font-size: 14px;
    font-style: italic;
    color: blue;
    font-weight: 900;
    padding-left: 10px;
    padding-right: 10px;
}