.video-wrap {
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  @media (max-width: 575px) {
    .video-wrap {
      height: 350px;
    }
  }
  
  .video-wrap a.popup-video {
    border-radius: 50%;
    -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
            box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
    width: 60px;
    height: 60px;
    line-height: 65px;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    color: #111;
    position: relative;
  }
  
  .video-wrap a.popup-video::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1s linear infinite;
            animation: pulse-border-2 1s linear infinite;
  }
  
  .video-wrap a.popup-video::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #cccccc;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
            animation: pulse-border-2 1.5s linear infinite;
  }
  
  .video-wrap.video-wrap-two {
    height: 460px;
  }
  
  @media (max-width: 575px) {
    .video-wrap.video-wrap-two {
      height: 350px;
    }
  }
  
  .video-wrap.video-wrap-two a.popup-video {
    height: 120px;
    width: 120px;
    line-height: 120px;
    font-size: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .video-wrap.video-wrap-two a.popup-video {
      height: 100px;
      width: 100px;
      line-height: 100px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.video-wrap-two a.popup-video {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
  }
  
  @media (max-width: 767px) {
    .video-wrap.video-wrap-two a.popup-video {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
  }
  
  .video-wrap.full-section {
    height: 700px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.full-section {
      height: 550px;
    }
  }
  
  @media (max-width: 767px) {
    .video-wrap.full-section {
      height: 380px;
    }
  }
  
  .video-wrap.full-section a.popup-video {
    height: auto;
    width: auto;
    background-color: transparent;
  }
  
  .video-wrap.full-section a.popup-video::before, .video-wrap.full-section a.popup-video::after {
    display: none;
  }
  
  .video-wrap.full-section a.popup-video img {
    max-width: 115px;
    max-height: 115px;
  }
  
  @media (max-width: 767px) {
    .video-wrap.full-section a.popup-video img {
      max-width: 80px;
      max-height: 80px;
    }
  }
  
  .video-wrap.video-about {
    height: 650px;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-wrap.video-about {
      height: 500px;
    }
  }
  
  @media (max-width: 767px) {
    .video-wrap.video-about {
      height: 300px;
    }
  }
  