
.contact-area {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    margin-top: 0px;
    height: 400px;
  }
  
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-area {
      margin-top: 100px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact-area {
      margin-top: 100px;
    }
  }
  
  @media (max-width: 575px) {
    .contact-area {
      margin-top: 80px;
    }
  }
  
  
  
/* .contact-area .contact-text {
    position: relative;
    padding: 100px 0;
  } */
  
.contact-area .contact-text,
.contact-area .contact-text h2.page-title {
    color: #fff;
}
  
  .contact-area .contact-text h2.page-title {
    font-size: 72px;
    letter-spacing: -1px;
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact-area .contact-text h2.page-title {
      font-size: 60px;
    }
  }
  
  @media (max-width: 575px) {
    .contact-area .contact-text h2.page-title {
      font-size: 50px;
    }
  }
  
  
  
.homecontact-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 48px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 4px;
  color:black;
  border: 2px solid  #F6BE00;
}

.homecontact-btn.btn-filled {
  background-color: #F6BE00;
  color: black;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}


.homecontact-btn.btn-filled:hover {
  background-color: transparent;
  color:white;
  -webkit-box-shadow: none;
          box-shadow: none;
}
