.service{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 400px; */
}

.service-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service img{
    height: 100px;
    width: 150px;
}

.service h5{
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 10px;
}
