.pagination-wrap {
    text-align: center;
    padding-top: 20px;
  }
  
  .pagination-wrap ul li {
    display: inline-block;
    margin: 0 5px;
  }
  
  @media (max-width: 575px) {
    .pagination-wrap ul li {
      margin-bottom: 8px;
    }
  }
  
  .pagination-wrap ul li a {
    height: 50px;
    width: 50px;
    line-height: 50px;
    color: #777;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    background-color: #fff;
    color: #111;
  }
  
  .pagination-wrap ul li a:hover {
    background-color: #bead8e;
    color: #fff;
  }
  
  .pagination-wrap ul li.active a {
    background-color: #bead8e;
    color: #fff;
  }
  